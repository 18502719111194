import React from 'react'

import Layout from '../components/layout'

const IndexPage = () => (
  <Layout>
    <div className="mdl-grid">
      <div className="mdl-cell mdl-cell--12-col">
        <div className="mdl-card mdl-shadow--2dp" style={{ width: 'auto' }}>
          <div className="mdl-card__title mdl-card--expand">
            <div className="nestedCards">
              <div className="mdl-cell mdl-cell--8-col">
                <p style={{ fontSize: '16px' }}>
                  <img
                    alt="SCD Logo"
                    style={{ float: 'left' }}
                    src="SCD-md.jpg"
                  />
                  Strategic Claims Direction (SCD) is a claims consulting firm
                  which works with companies, insurers, risk pools, public
                  entities, and third party administrators to improve claims
                  management procedures and performance. Our goal is to work
                  with you to responsibly manage your claims by improving
                  efficiency and effectiveness to reduce claims costs and
                  expenses. We can help in a variety of ways, depending upon
                  your most critical needs.{' '}
                </p>
                <strong>
                  <u>
                    We often hear comments or questions from our clients such
                    as:
                  </u>
                </strong>
                <ol>
                  <li>
                    <strong>
                      We need a claims audit from an independent and experienced
                      claims professional because
                    </strong>
                  </li>
                  <ul>
                    <li>our board has requested one</li>
                    <li>a state agency requires one every X years</li>
                    <li>
                      AGRiP requires one to meet certification requirements
                    </li>
                  </ul>
                  <p style={{ fontStyle: 'italic' }}>OR...</p>
                  <p>
                    <strong>
                      We are not sure our TPA, insurer, or self-administered
                      claims program are managing claims as they should. How can
                      we find out?{' '}
                    </strong>
                  </p>
                  <p style={{ fontStyle: 'italic' }}>OR...</p>
                  <p>
                    <strong>
                      Our claims costs keep going up even though our employee
                      count is down OR our incident count is down OR we have
                      done a lot to improve our claims and safety program. We
                      are not sure why this is the case. Can you help us?
                    </strong>
                  </p>
                  <p>
                    SCD can perform a cost-effective claims audit of a sample of
                    claims to objectively measure claims performance against
                    leading industry practices and your company's specific
                    performance requirements. We will then provide you with
                    recommendations for improvement where it is needed. In many
                    cases we can perform this through on-line access to the
                    claims system, eliminating travel expenses.
                  </p>
                  <br />

                  <li>
                    <strong>
                      We have questions about the reasonableness of our case
                      reserves. How can we evaluate the reserves?{' '}
                    </strong>
                  </li>
                  <p>
                    SCD can review the claims reserving processes and procedures
                    to determine if they are reasonable and are being followed.
                    We will also prepare independent assessments of the case
                    reserves for a sample of claims. If needed, we can provide
                    this information to your casualty actuary.
                  </p>
                  <br />

                  <li>
                    <strong>
                      We need to select a third party administrator (TPA) but
                      need help defining our needs and evaluating responses so
                      we get the best TPA for us. Can you help?
                    </strong>
                  </li>
                  <p>
                    SCD will prepare a needs assessment based on your company
                    and its specific needs. We will then develop performance
                    requirements, help create the Request for Proposal (RFP),
                    and assist you through the selection process. We can also
                    help you through the implementation or transition process if
                    needed.
                  </p>
                  <br />

                  <li>
                    <strong>
                      We are concerned that our claims processes are
                      inefficient. How do we assess that? Can you help?
                    </strong>
                  </li>
                  <p>
                    SCD can perform a claims process efficiency assessment to
                    compare your processes and procedures with those recognized
                    as using leading industry practices. We will identify
                    processes to retain as well as identify redundant or
                    unnecessary steps that should be eliminated or revised. We
                    can also help you evaluate the roles and responsibilities of
                    your claims personnel and determine if certain functions or
                    responsibilities should be delegated or assigned to others.
                  </p>
                </ol>
                <p>
                  Based on our assessment of your program, we will provide you
                  with priorities, recommendations, and a roadmap to improve
                  your claims program.{' '}
                </p>
              </div>

              <div className="mdl-cell mdl-cell--4-col">
                <div
                  className="demo-card-square mdl-card mdl-shadow--6dp"
                  style={{ minWidth: '280px' }}
                >
                  <div id="bio" className="mdl-card__title mdl-card--expand">
                    <img
                      alt="Gary Jennings"
                      className="img-circle"
                      src="22Feb2016-cropped.jpg"
                    />
                    <div>
                      <h2
                        className="mdl-card__title-text"
                        style={{ marginLeft: '15px' }}
                      >
                        Gary Jennings
                      </h2>
                    </div>
                  </div>
                  <div className="mdl-card__supporting-text">
                    <small>
                      As founder and principal of Strategic Claims Direction,
                      Gary has over 40 years experience in claims management,
                      with 20 years of claims consulting experience. He has
                      earned the CPCU, ARM, ALCM, AIC, ARe, and SCLA
                      professional designations.
                    </small>
                    <small>
                      Gary has experienced many different facets of claims
                      administration, having worked for an insurer, a TPA, an
                      independent adjusting firm, a corporate claims
                      organization, and two of the Big 4 consulting firms prior
                      to forming SCD. He brings real-world solutions to meet
                      your claims management needs. Click{' '}
                      <a href="docs/Gary%20Jennings%20-%20July%202016.docx">
                        here
                      </a>{' '}
                      to see Gary's resume or{' '}
                      <a href="https://www.linkedin.com/pub/gary-jennings/1/428/346">
                        <img
                          src="https://static.licdn.com/scds/common/u/img/webpromo/btn_liprofile_blue_80x15.png"
                          width="80"
                          height="15"
                          border="0"
                          alt="View Gary Jennings's profile on LinkedIn"
                        />
                      </a>
                      .
                    </small>
                  </div>
                  <div
                    className="mdl-card__actions mdl-card--border"
                    style={{
                      background: '#00518d',
                      color: 'white',
                      fontWeight: 'bold',
                    }}
                  >
                    Contact Us @ (678) 520-3739
                    <br /> Or{' '}
                    <a
                      href="mailto:Gary.Jennings@StrategicClaimsDirection.com"
                      style={{ color: 'white' }}
                    >
                      Send Us An Email
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
